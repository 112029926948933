// assets
import {
  IconChartHistogram,
  IconClipboardData,
  IconReportMoney,
} from "@tabler/icons";

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const salesrep = {
  id: "salesrep",
  title: "Reporting",
  type: "group",
  children: [
    {
      id: "sales_dashboard",
      title: "Sales Dashboard",
      type: "item",
      url: "/salesrep/sales_dashboard",
      icon: IconChartHistogram,
      breadcrumbs: false,
    },
    {
      id: "ops_dashboard",
      title: "Ops Dashboard",
      type: "item",
      url: "/salesrep/ops_dashboard",
      icon: IconClipboardData,
      breadcrumbs: false,
    },
    {
      id: "inside_sales_dashboard",
      title: "Inside Sales Dashboard",
      type: "item",
      url: "/salesrep/inside_sales_dashboard",
      icon: IconReportMoney,
      breadcrumbs: false,
    },
    {
      id: "business_dashboard",
      title: "Business Dashboard",
      type: "item",
      url: "/salesrep/business_dashboard",
      icon: IconReportMoney,
      breadcrumbs: false,
    },
    {
      id: "irs_dashboard",
      title: "IR Dashboard",
      type: "item",
      url: "salesrep/irs_dashboard",
      icon: IconReportMoney,
      breadcrumbs: false,
    },
    // {
    //   id: "daily_details",
    //   title: "Daily Sales Details",
    //   type: "item",
    //   url: "/salesrep/daily_details",
    //   icon: icons.IconDashboard,
    //   breadcrumbs: true,
    // },
    // {
    //   id: "mtd_summary",
    //   title: "MTD Summary",
    //   type: "item",
    //   url: "/salesrep/mtd_summary",
    //   icon: icons.IconDashboard,
    //   breadcrumbs: true,
    // },
    // {
    //   id: "mtd_details",
    //   title: "MTD Details",
    //   type: "item",
    //   url: "/salesrep/mtd_details",
    //   icon: icons.IconDashboard,
    //   breadcrumbs: true,
    // },
    // {
    //   id: "ytd_summary",
    //   title: "YTD Summary",
    //   type: "item",
    //   url: "/salesrep/ytd_summary",
    //   icon: icons.IconDashboard,
    //   breadcrumbs: true,
    // },
    // {
    //   id: "ytd_details",
    //   title: "YTD Details",
    //   type: "item",
    //   url: "/salesrep/ytd_details",
    //   icon: icons.IconDashboard,
    //   breadcrumbs: true,
    // },
    // {
    //   id: "daily_margin_exception",
    //   title: "Daily Margin Exception ",
    //   type: "item",
    //   url: "/salesrep/daily_margin_exception",
    //   icon: icons.IconDashboard,
    //   breadcrumbs: true,
    // },
    // {
    //   id: "monthly_margin_exception",
    //   title: "Monthly Margin Exception",
    //   type: "item",
    //   url: "/salesrep/monthly_margin_exception",
    //   icon: icons.IconDashboard,
    //   breadcrumbs: true,
    // },
  ],
};

export default salesrep;
