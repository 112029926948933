import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import IRDashboard from "views/Users/IRDashboard";

// dashboard routing
const LoginPage = Loadable(lazy(() => import("views/login-page/LoginPage")));
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const SalesDashboard = Loadable(
  lazy(() => import("views/salerep/SalesDashboard"))
);
const OpsDashboard = Loadable(lazy(() => import("views/salerep/OpsDashboard")));
const BusinessDashboard = Loadable(
  lazy(() => import("views/salerep/BusinessDashboard"))
);
const InsideSalesDashboard = Loadable(
  lazy(() => import("views/salerep/InsideSalesDashboard"))
);
const IRsDashboard = Loadable(lazy(() => import("views/salerep/IRDashboard")));
const PriceLists = Loadable(lazy(() => import("views/price-lists/PriceLists")));
const PriceListsBulk = Loadable(
  lazy(() => import("views/price-lists/PriceListsBulk"))
);
const ShoppingLists = Loadable(
  lazy(() => import("views/shopping-lists/ShoppingLists"))
);
const ShoppingListBulk = Loadable(
  lazy(() => import("views/shopping-lists/ShoppingListBulk"))
);
const Cart = Loadable(lazy(() => import("views/cart/Cart")));
const AccountRecords = Loadable(
  lazy(() => import("views/account-records/index"))
);

const Order = Loadable(lazy(() => import("views/order/order")));
const OrderDetails = Loadable(lazy(() => import("views/order/orderDetails")));
const Companies = Loadable(lazy(() => import("views/company/company")));
const CompanyDetails = Loadable(
  lazy(() => import("views/company/company-details"))
);
const NewCompany = Loadable(lazy(() => import("views/company/new-company")));
const BulkUploads = Loadable(lazy(() => import("views/bulk-uploads/index")));
const ProductIntake = Loadable(
  lazy(() => import("views/product-intake/index"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isAuthenticated, roles) => {
  return {
    path: "/",
    element: isAuthenticated ? <MainLayout /> : <LoginPage />,
    children: [
      {
        path: "/",
        element: <PriceLists />,
      },
      {
        path: "order",
        children: [
          {
            path: "cart",
            element: <Cart />,
          },
          {
            path: "orders",
            element: <Order />,
          },
          {
            path: "orders/:orderId",
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: "user",
        children: [
          ...(roles?.includes("Bttn Engineering")
            ? [
                {
                  path: "ir-dashboard",
                  element: <IRDashboard />,
                },
              ]
            : []),
        ],
      },
      {
        path: "company",
        children: [
          ...(roles?.includes("Bttn Engineering")
            ? [
                {
                  path: "create-company",
                  element: <NewCompany />,
                },
                {
                  path: "edit-company/:company_id",
                  element: <NewCompany />,
                },
                {
                  path: "companies",
                  element: <Companies />,
                },

                {
                  path: "companies/:company_id",
                  element: <CompanyDetails />,
                },
              ]
            : []),
        ],
      },
      {
        path: "dashboard",
        children: [
          {
            path: "default",
            element: <DashboardDefault />,
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "price-lists",
            element: <PriceLists />,
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "bulk-uploads",
            element: <BulkUploads />,
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "shopping-lists",
            element: <ShoppingLists />,
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "price-lists-bulk",
            element: <PriceListsBulk />,
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "shopping-lists-bulk",
            element: <ShoppingListBulk />,
          },
        ],
      },
      {
        path: "account-records",
        element: <AccountRecords />,
      },
      {
        path: "salesrep",
        children: [
          ...(roles?.includes("Bttn Engineering") ||
          roles?.includes("Sales Representative")
            ? [
                {
                  path: "sales_dashboard",
                  element: <SalesDashboard />,
                },
                {
                  path: "ops_dashboard",
                  element: <OpsDashboard />,
                },
                {
                  path: "inside_sales_dashboard",
                  element: <InsideSalesDashboard />,
                },
                {
                  path: "business_dashboard",
                  element: <BusinessDashboard />,
                },
              ]
            : []),
          ...(roles?.includes("Independent Representative")
            ? [
                {
                  path: "irs_dashboard",
                  element: <IRsDashboard />,
                },
              ]
            : []),
          // {
          //   path: "daily_summary",
          //   element: <DailySalesSummary />,
          // },
          // {
          //   path: "mtd_summary",
          //   element: <DailySalesSummary />,
          // },
          // {
          //   path: "ytd_summary",
          //   element: <DailySalesSummary />,
          // },
          // {
          //   path: "mtd_details",
          //   element: <DailySalesDetails />,
          // },
          // {
          //   path: "daily_details",
          //   element: <DailySalesDetails />,
          // },
          // {
          //   path: "daily_margin_exception",
          //   element: <DailySalesDetails />,
          // },
          // {
          //   path: "monthly_margin_exception",
          //   element: <DailySalesDetails />,
          // },
        ],
      },
      
        roles?.includes("Bttn Engineering") ||
        roles?.includes("Sales Representative")
          ? {
              path: "product-intake",
              element: <ProductIntake />,
            }
          : [],
    ],
  };
};
export default MainRoutes;
